import { primaryBGColor } from '../../constants/colors';
import { projects } from '../../constants/content';
import ProjectItem from './ProjectItem';

function Projects() {
	return (
		<section className='min-h-screen flex flex-col px-8 md:px-20 md:justify-start justify-center text-white' style={{ background: primaryBGColor }}>
			<h2 className='text-5xl font-bold mt-20'>Projects</h2>
			<br />
			{projects.map((item, index) => {
				return (
					<ProjectItem
						key={index}
						text={item.text}
						imgURL={item.imgURL}
						link={item?.link}
						demo={item?.demo}
						title={item.title}
						appStore={item?.appStore}
						reverse={index % 2 === 0 ? false : true}
					/>
				);
			})}
		</section>
	);
}

export default Projects;
