
import Header from '../components/header/Header';
import Nav from '../components/nav/Nav';
import { Element } from 'react-scroll';
import Projects from '../components/projects/Projects';
import Footer from '../components/footer/Footer';
import About from '../components/about/About';
function Home() {
	return (
		<>
			<Nav />
			<Element name='header'>
				<Header />
			</Element>
			<Element name='about'>
				<About />
			</Element>
			<Element name='projects'>
				<Projects />
			</Element>
			<Element name='footer'>
				<Footer />
			</Element>
		</>
	);
}

export default Home;
