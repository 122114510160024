import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { primaryBGColor } from '../../constants/colors';

interface SkillItemProps {
	title: string;
	icon?: IconProp;
	imageIcon?: string;
	index: number;
	show?: boolean;
}

function SkillItem({ title, icon, imageIcon, index, show = false }: SkillItemProps) {
	const [hidden, setHidden] = useState(true);
	useEffect(() => {
		if (show) {
			setTimeout(() => {
				setHidden(false);
			}, index * 200);
		}

		return;
	}, [index, show]);
	return (
		<div className={`flex mx-2 animate-fade ${hidden ? 'hidden' : ''}`}>
			<div className='py-2 px-8 shadow-default items-center align-center text-white text-center self-center' style={{ backgroundColor: primaryBGColor }}>
				<p>{title}</p>
			</div>{' '}
			<div className='p-2 bg-gray-200 shadow-default' style={{ minWidth: 50 }}>
				{icon ? <FontAwesomeIcon fixedWidth className='' icon={icon} size='2x' /> : null}
				{imageIcon ? <img className='object-contain' style={{ minWidth: 32 }} src={imageIcon} width={32} height={32} alt='logo' /> : null}
			</div>
		</div>
	);
}

export default SkillItem;
