import { primaryBGColor } from '../../constants/colors';

function Footer() {
	return (
		<footer className='text-center text-white text-lg p-4' style={{ background: primaryBGColor }}>
			<p>&copy; All rights reserved. Saul Plateros 2021.</p>
		</footer>
	);
}

export default Footer;
