import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAndroid, faAngular, faApple, faGitAlt, faGithub, faLinux, faPython, faReact, faSwift } from '@fortawesome/free-brands-svg-icons';

export const headerTitle = `Hi 👋 , I'm Saul.`;

export const headerSummary = `I'm a full stack web developer from Southern California who enjoys using logic and reason to find an elegant solution to a complex problem. I am motivated to
learn how to leverage industry leading frameworks and approach everyday as an opportunity to extend my knowledge.`

export const typicalContent = [
	'A Full-Stack Developer. 🖥',
	1500,
	'A Front-End Developer. 💻',
	1500,
	'A Mobile Developer.📱',
	1500,
	'A Problem Solver. 🔧',
	1500,
	'A Claw Machine Enthusiast. 🕹',
	1500,
];

interface projectsType {
	text: string;
	title: string;
	imgURL: string;
	link?: string;
	demo?: string;
	appStore?: string;
}

export const projects: projectsType[] = [
	{
		text: `Today is a cross-platform cloud-enabled mobile application powered by React Native (Frontend) and Google Firebase (Backend).\nThe application was built with simplicity in mind and help deliver the latest news.\nThe app uses an easy to use UI so that only necessary information is displayed. Making it easier for one to find the top headlines, or search for news articles in a quick and efficient manner.
    `,
		imgURL: 'https://github.com/sauldesigns/today-rn/raw/main/images/Homepage.png',
		link: 'https://github.com/sauldesigns/today-rn',
		title: 'Today',
	},
	{
		text: `Produ:Task is a cross-platform cloud-enabled mobile application powered by Flutter Framework and Google Firebase.\nThe application was built with simplicity in mind so that users are able to focus on completing their tasks. Making it easier for one to stay productive.
    `,
		imgURL: '/img/produtask/tasks.PNG',
		title: 'Produ:Task',
		link: 'https://github.com/sauldesigns/produ-task',
	},
	{
		text: `Clique-ers is a sticker pack for iMessage. That allows people to express their emotions through stickers.\nThis group of friends help you show expressions and emotions. Some will have similar emotions and others will have certain mannerisms/expressions that tie in with their personality. 
    `,
		imgURL: '/img/clique-ers.png',
		// link: 'https://github.com/sauldesigns/netflix-clone',
		title: 'Clique-ers',
		appStore: 'https://apps.apple.com/tt/app/clique-ers/id1521409658',
	},
	{
		text: `This web application is a very basic Netflix clone that replicates its layout using React. It makes calls to TMDB API in order to get dynamic data into the rows.\nEach listing will be able to display a movie trailer if it finds one. The user will have the ability to create an account and favorite shows/movies.\nAuthenthication and Database will be done with Firebase.
    `,
		imgURL: 'https://github.com/sauldesigns/netflix-clone/raw/master/screenshots/homepage.png',
		link: 'https://github.com/sauldesigns/netflix-clone',
		title: 'Netflix Clone',
		demo: 'https://netflix-clone.sauldesigns.me/',
	},
	{
		text: `Oddlie Ollie is a sticker pack for iMessage. That allows people to express their emotions through stickers.\nOllie is the perfect little sassy octopus that can help show the best emotions and humor, thus keeping conversations fun..
    `,
		imgURL: '/assets/ollie.jpeg',
		// link: 'https://github.com/sauldesigns/netflix-clone',
		title: 'Oddlie Ollie',
		appStore: 'https://apps.apple.com/us/app/oddlie-ollie/id1471442488#?platform=iphone',
	},
];

interface skillsType {
	title: string;
	icon?: IconProp;
	imageIcon?: string;
}

export const skills: skillsType[] = [
	{
		title: 'Angular',
		icon: faAngular,
	},
	{
		title: 'React',
		icon: faReact,
	},
	{
		title: 'Javascript',
		imageIcon: '/assets/javascript-icon.svg',
	},
	{
		title: 'TypeScript',
		imageIcon: '/assets/typescriptlang-icon.svg',
	},
	{
		title: '.NET',
		imageIcon: '/assets/dotnet-icon.svg',
	},
	{
		title: 'C#',
		imageIcon: '/assets/csharp-icon.svg',
	},
	{
		title: 'Python',
		icon: faPython,
	},

	{
		title: 'Swift',
		icon: faSwift,
	},
	{
		title: 'Github',
		icon: faGithub,
	},
	{
		title: 'Git',
		icon: faGitAlt,
	},
	{
		title: 'iOS',
		icon: faApple,
	},
	{
		title: 'Android',
		icon: faAndroid,
	},

	{
		title: 'Linux',
		icon: faLinux,
	},
];
