import { primaryBGColor } from '../../constants/colors';
import { ReactTypical } from '@deadcoder0904/react-typical';
import { typicalContent, headerSummary, headerTitle } from '../../constants/content';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

function Header() {
	const goToGithub = () => {
		window.open('https://github.com/sauldesigns', '_blank');
	};

	const goToLinkedIn = () => {
		window.open('https://linkedin.com/in/saul-plateros', '_blank');
	};

	const goToTwitter = () => {
		window.open('https://twitter.com/sauldesigns', '_blank');
	};

	return (
		<section className='min-h-screen flex items-start  md:items-center md:mt-0 px-4 md:px-20 md:justify-start justify-center' style={{ background: primaryBGColor }}>
			<article className='text-white text-center mt-20 md:mt-0  md:text-left'>
				<p className='text-4xl md:text-4xl font-bold'>
					{headerTitle} <br />
					<ReactTypical className='inline-block text-2xl' wrapper='span' steps={typicalContent} loop={Infinity} />
				</p>
				<br />
				<p className='text-lg md:text-xl max-w-lg md:max-w-2xl'>
					{headerSummary}
				</p>
				<br />
				<div className='flex mt-4 justify-center md:justify-start'>
					<FontAwesomeIcon className='mr-6 ' size='3x' cursor='pointer' color='white' icon={faGithub} fixedWidth onClick={() => goToGithub()} />
					<FontAwesomeIcon className='mr-6 ' cursor='pointer' size='3x' color='white' icon={faLinkedin} fixedWidth onClick={() => goToLinkedIn()} />
					<FontAwesomeIcon cursor='pointer' size='3x' color='white' icon={faTwitter} fixedWidth onClick={() => goToTwitter()} />
				</div>
				<br />
				<Link to='about' smooth={true} duration={1000}>
					<button className='animate-float bg-indigo-500 px-10 py-3 text-xl uppercase mt-10 rounded-lg'>Discover More</button>
				</Link>
			</article>
		</section>
	);
}

export default Header;
