import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useState } from 'react';
import { primaryBGColor } from '../../constants/colors';
import { skills } from '../../constants/content';
import SkillItem from '../skill-item/SkillItem';
const HEIGHT = window.screen.height;
function About() {
	const [show, setShow] = useState(false);
	useScrollPosition((scrollPos) => {
		if (scrollPos.currPos.y < -1 * (HEIGHT - 100)) {
			setShow(true);
		}
	});
	return (
		<section className='min-h-screen flex flex-col pb-20 px-8 md:px-20 md:justify-start justify-center bg-white'>
			<h2 className='text-5xl font-bold mt-20'>About Me</h2>
			<br />

			<article className='flex flex-col justify-center mb-20'>
				<h3 className='font-bold text-3xl text-center'>Education</h3>
				<br />
				<div className='px-20 py-8  self-center text-white flex flex-col items-center' style={{ backgroundColor: primaryBGColor }}>
					<p className='text-xl text-center text-bold'>Bachelor's of Science in Computer Science</p>
					<p className='text-md text-center'>University of California - Riverside 2019</p>
					<img className='object-contain mt-2' src='/assets/ucr-logo.png' width='100' alt='ucr logo' />
				</div>
			</article>
			<article className='flex flex-col justify-center mb-5'>
				<h3 className='font-bold text-3xl text-center'>Skills</h3>
				<br />
				<div className='md:w-10/12 self-center grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'>
					{skills.map((item, index) => {
						return (
							<div key={index} className=''>
								<SkillItem title={item?.title} icon={item?.icon} imageIcon={item?.imageIcon} index={index} show={show} />
							</div>
						);
					})}
				</div>
			</article>
		</section>
	);
}

export default About;
