interface ProjectItemProps {
	text: string;
	imgURL: string;
	link: string | undefined;
	title: string;
	demo: string | undefined;
	appStore: string | undefined;
	reverse?: boolean;
}
function ProjectItem({ text, imgURL, link, title, demo, appStore, reverse = false }: ProjectItemProps) {
	const onPress = (goToLink: string) => {
		window.open(goToLink, '_blank');
	};

	return (
		<article className='mb-16 flex flex-col justify-center items-center'>
			<h2 className={`text-4xl text-center font-bold mb-8 ${reverse ? 'md:text-left' : 'md:text-right'}`}>{title}</h2>
			<div
				className={`flex flex-col text-center ${reverse ? 'md:text-right' : 'md:text-left'} md:flex-row items-center  ${
					reverse ? 'md:flex-row-reverse' : 'md:flex-row'
				} max-w-5xl`}
			>
				<p className={`${reverse ? 'md:ml-20' : 'md:mr-20'}  text-xl whitespace-pre-wrap`}>{text}</p>
				{demo ? (
					<div className='object-cover'>
						<img className='object-contain' src={imgURL} alt='project' />
					</div>
				) : (
					<img className='object-contain' src={imgURL} alt='project' width={200} />
				)}
			</div>
			<div className={`flex mt-20 md:mt-4 justify-center  ${reverse ? 'md:justify-end' : 'md:justify-start'}`}>
				{link ? (
					<button className='animate-float bg-indigo-500 px-10 py-3 text-xl uppercase rounded-lg mr-4' onClick={() => onPress(link)}>
						View on Github
					</button>
				) : null}

				{demo ? (
					<button className='animate-float bg-green-500 px-10 py-3 text-xl uppercase rounded-lg mr-4' onClick={() => onPress(demo)}>
						Demo
					</button>
				) : null}

				{appStore ? (
					<img
						className='animate-float text-xl uppercase rounded-lg mr-4 cursor-pointer'
						width='150'
						src='/assets/app_store.svg'
						alt='appstore'
						onClick={() => onPress(appStore)}
					/>
				) : null}
			</div>
		</article>
	);
}

export default ProjectItem;
