import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { primaryBGColor } from '../../constants/colors';
import { navigationLinks } from '../../constants/links';

function Nav() {
	const [showMenu, setShowMenu] = useState(false);
	const onLinkClick = (link: any) => {
		if (link?.isExternal) {
			window.open(link?.link, '_blank');
		} else {
			setShowMenu(false);
		}
	};

	return (
		<nav className='sticky top-0 z-50' style={{ background: primaryBGColor }}>
			<div className='flex item-center justify-between w-10/12 mx-auto py-3 text-white'>
				<h1 className='text-2xl font-bold mr-10'>SAUL PLATEROS</h1>
				<button className='md:hidden'>
					<FontAwesomeIcon size='lg' icon={faBars} cursor='pointer' onClick={() => setShowMenu(!showMenu)} fixedWidth />
				</button>
				<div
					className={`absolute mt-12 md:mt-0 md:relative top-16 left-0 md:top-0 z-20 flex flex-col md:flex-row md:space-x-6 font-semibold w-full md:w-auto  shadow-md md:rounded-none md:shadow-none md:bg-transparent p-6 pt-0 md:p-0 md:opacity-100 md:visible md:block ${
						showMenu ? 'text-center pt-4 transition duration-500 transition-opacity opacity-100' : 'transition duration-500 transition-opacity invisible opacity-0'
					}`}
					style={{ background: primaryBGColor }}
				>
					{navigationLinks.map((link, index) => {
						return (
							<Link isDynamic={true} spy key={index} className='mb-6' to={link.to} smooth={true} onClick={() => onLinkClick(link)} duration={1000}>
								<span className='text-lg mr-4 cursor-pointer'>{link.text}</span>
							</Link>
						);
					})}
				</div>
			</div>
		</nav>
	);
}

export default Nav;
